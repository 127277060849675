import { useState, useEffect } from "react";
import "../css/contactUs.css";
// import DirectorImg from "../assets/umer.jpg";
export const ContactInfo = () => {
  const [name, setName] = useState("");

  useEffect(()=>{
    window.scroll(0,0)
  })

  function cleartext() {
    setName("");
  }
  return (
    <div className="contactUsContainer">
      <h1>Contact Us</h1>
      <div className="formContainer">
        <div className="formInput">
          <textarea
            type="text"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
            placeholder="Enter your Message"
          />

          <div className="contactUsbtn" onClick={cleartext}>
            <a
              href={
                "https://web.whatsapp.com/send?phone=7006220012&text=" +
                name +
                "&app_absent=0"
              }
              target="blank"
            >
              Message Us
            </a>
          </div>
        </div>
        {/* <div className="directorbox">
          <div className="directorImgContainer">
            <img className="directorImg" src={DirectorImg} alt="director" />
          </div>
          <strong>Director and Founder </strong>Mr. Umer Rasool
        </div> */}
      </div>
      <div className="contactNum">
        <div>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="mailto:umer@sukhnaginfra.com"
          >
            Email : umer@sukhnaginfra.in
          </a>
        </div>
        <div>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="tel:917006220012"
          >
            Phone : +917006220012
          </a>
        </div>
        <div className="paddMap">
        <iframe title="address" width="100%" height="100%" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=34.120322389879256, 74.68280883098676&amp;q=Sukhnag Infra, SOZEITH, opp. HP FILLING STATION, Kirmani Abad, Tangmarg&amp;ie=UTF8&amp;t=&amp;z=15&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br />

        </div>
      </div>
    </div>
  );
};
