import React from "react";
import "../css/footer.css";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerP">
      <div>Sukhnag Infra Opc Pvt Ltd</div>
      <div>
        First Floor Bhat Complex Opposite Trison City Kirmani Abad, Srinagar, Jammu and Kashmir, 190017

      </div>
      <div className="quickLinks">
        <div className="iconsContact">
          <div>Visit Our Social Media</div>
          <div className="iconsMedia">
            <SocialIcon url="https://in.linkedin.com/company/sukhnag-infra-pvt-ltd" target="blank" />
            <SocialIcon url="https://www.facebook.com/people/Sukhnag-Infra-Pvt-Ltd/61554792516014/?mibextid=ZbWKwL" target="blank"/>
            <SocialIcon url="https://www.instagram.com/sukhnag_infra_pvt_ltd?igsh=ZGUzMzM3NWJiOQ==" target="blank"/>
          </div>
        </div>
        <div>
          Copyright © {new Date().getFullYear()} Sukhnag Infra Opc Pvt Ltd - All Rights Reserved.
        </div>
      </div>
      </div>
    </div>
  );
};

export default Footer;
